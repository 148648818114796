<template>
  <main>
    <div class="header">
      <h1>Board and Investors</h1>
    </div>
    <div class="person-block">
      <div class="photo">
        <img src="@/assets/board/1597053813467.jpeg" alt="" />
      </div>
      <div class="person-info">
        <h2 class="person-name">Lesego Finger</h2>
        <p class="person-role">Founder & CTO</p>
        <p class="person-bio">
          Experienced Self-taught Software Engineer with a demonstrated history of working on real life projects that
          have been used by thousands of people. I enjoy developing products that add value to other people's lives.
        </p>
      </div>
    </div>
    <div class="person-block">
      <div class="photo">
        <img src="@/assets/board/1574191653226.jpeg" alt="" />
      </div>
      <div class="person-info">
        <h2 class="person-name">Kagisho Masae</h2>
        <p class="person-role">Co-Founder & CEO</p>
        <p class="person-bio">
          Client experience enthusiast. Experience in customer service, partner relations, loyalty & rewards programs,
          project management & digital marketing. Digital junky in the financial services industry. One Young World
          Ambassador. Passionate about giving back & the empowerment of the previously disadvantaged.
        </p>
      </div>
    </div>
    <div class="person-block">
      <div class="photo">
        <img src="@/assets/board/1517248411281 1.jpg" alt="" />
      </div>
      <div class="person-info">
        <h2 class="person-name">Kundayi Munzara</h2>
        <p class="person-role">Investor & Advisor</p>
        <p class="person-bio">
          Kundayi is a co-founder and executive director of Sesfikile Capital, an asset manager focussed on listed
          property investments on behalf of SA pension funds and retail investors with over $1 billion in assets under management.
          Kundayi is also a co-founding director of KP International, an angel investment holding company focussing on
          early stage start-ups predominately founded by African entrepreneurs. He holds a BSc. (Hons) in Property
          Studies from the University of Cape Town (UCT). He completed UK Financial Services Authority (“FSA”) exams for
          approved person status and holds a CFA designation. He recently completed his Private Equity and Venture
          Capital course and Program for Leadership Development (PLD) at Harvard Business School. He began his career at
          Old Mutual Properties as a property valuation intern, and in 2005 continued at Investec Private Bank’s
          property risk team valuing properties for lending purposes. He then moved to Investec Property Investments as
          a buy-side analyst and was subsequently appointed head of research for the investment team with analysts in
          South Africa, UK and Australia. Kundayi also played a key role in the development of Investec’s global listed
          property offering while based in London, which included investments in UK, European and Australian REITs.
        </p>
      </div>
    </div>
    <div class="person-block">
      <div class="photo">
        <img src="@/assets/board/1584028297642 1.jpg" alt="" />
      </div>
      <div class="person-info">
        <h2 class="person-name">Itumeleng Merafe</h2>
        <p class="person-role">Investor & Advisor</p>
        <p class="person-bio">
          Itumeleng is the CEO of Investec Private Bank and Business Development at Investec For Business (IFB), a
          division of Investec Bank Limited. He has been with Investec for over 14 years covering roles within the
          private bank and corporate and institutional banking, most recently as Head of Treasury Sales and Structuring
          where he led of team that focused primarily on derivative solutions to assist clients in managing risk across
          interest rate and foreign exchange markets before joining the leadership of the newly formed IFB.
          Itumeleng holds a BCom in Economics and investment management. He has also completed the Finance Executive
          Development Programme at Cass Business School in London, holds an ACI dealing certificate and a Masters in the
          management of finance and investments from Wits Business School. Itumeleng was recognized in the Mail &
          Guardian Top 200 Young South African’s in 2017 and is a 2017 Archbishop Tutu Fellow and completed the Harvard
          Business School Programme for Leadership Development (PLD) in 2019.
        </p>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Board_And_Investor",
};
</script>

<style scoped>
main {
  background-image: url("../../assets/careerspage/Desktop - 1.svg");
  background-size: auto;
}

.person-block {
  text-align: initial;
  margin: 8%;
  display: flex;
}

.photo>img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.person-info {
  margin-left: 3%;
}

.person-info .person-role {
  font-size: larger;
  font-weight: bold;
  /* color: #d87e7e; */
  color: mediumpurple;
}

.header {
  margin-top: 5%;
}

h1 {
  font-size: xxx-large;
}

@media screen and (max-width: 950px) {
  .person-block {
    display: block;
    text-align: inherit;
  }
}
</style>